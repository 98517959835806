<template>
	<v-card flat class="wr_grey_1">
		<!-- Asked question -->
		<div>
			<div class="font-weight-bold subtitle-1">
				Keywords for "{{ step1_question }}"-
			</div>
		</div>

		<v-chip-group column class="mt-2">
			<v-chip
				v-for="keyword in keywords"
				:key="keyword.id"
				color="info"
				:outlined="!keyword.selected"
				@click="markSelected(keyword.id)"
			>
				<v-icon v-if="keyword.selected" left
					>$vuetify.icons.values.check</v-icon
				>
				{{ keyword.name }}
			</v-chip>
		</v-chip-group>

		<div align="center" class="mt-3">
			<AppButton
				label="app.submit"
				icon="send"
				@click="submit()"
				color="success"
			></AppButton>
		</div>

		<v-list flat subheader class="wr_grey_1 mt-5">
			<v-list-item-group multiple active-class="">
				<v-list-item v-for="(item, index) in step4_answer" :key="index">
					<template v-slot:default="{ active }">
						<v-list-item-action>
							<v-checkbox
								v-model="step4_selected_sources"
								:value="item"
							></v-checkbox>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
							<v-list-item-subtitle>
								{{ item.description }}<br />
								<a :href="item.url" target="_blank" rel="noopener noreferrer">
									{{ item.url }}
								</a>
							</v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryKeywordsSteps } from "@/utils/steps";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

export default {
	name: "QueryEvaluateKeywords",

	mixins: [askQuestionMixin, toolsQueryMixin],

	computed: {
		...mapFields("query", {
			step1_question: "keywords.step1_question",
			step3_keywords: "keywords.step3_keywords",
			step3_answer: "keywords.step3_answer",
			step4_keywords: "keywords.step4_keywords",
			step4_answer: "keywords.step4_answer",
			step4_selected_sources: "keywords.step4_selected_sources",
			current_step: "keywords.current_step",
		}),

		keywords() {
			return this.step4_keywords;
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtk-move-to-generate-report", (payload) => {
				try {
					if (this.step4_selected_sources.length < 5) {
						throw new Error("Please select at least 5 source.", {
							cause: "werCustom",
						});
					}

					if (!this.step4_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}

					this.current_step = queryKeywordsSteps[4];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async markSelected(keyword_id) {
			this.step4_keywords = this.step4_keywords.map((keyword) => {
				if (keyword.id == keyword_id) {
					return { ...keyword, selected: !keyword.selected };
				}
				return keyword;
			});
		},

		async init() {
			if (!this.step4_keywords || !this.step4_keywords.length) {
				let non_relevant_keywords = this.step3_answer.result
					.replace(/(\r\n|\n|\r)/gm, "")
					.split(", ");

				this.step4_keywords = [
					...this.step3_keywords
						.filter((item) => !non_relevant_keywords.includes(item.name))
						.map((item) => {
							return { ...item, selected: false };
						}),
				];
			}
		},

		async submit() {
			try {
				this.$loader.start();

				let selected_keywords = this.step4_keywords
					.filter((item) => item.selected)
					.map((item) => item.name);

				let response = await this.prepareQuery(
					`Generate a JSON array of 8 sources about the ${
						this.step1_question
					} using the following keywords- ${selected_keywords.join(
						",",
					)}. Each source should include a title, URL, description."`,
				);

				if (response && response.result) {
					this.step4_answer = JSON.parse(response.result);
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtk-move-to-generate-report");
	},
};
</script>

<style></style>
