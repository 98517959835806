<template>
	<v-card flat class="wr_grey_1">
		<v-card-text>
			<component
				v-if="current_step"
				:is="current_step.component_name"
			></component>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import AskQuestion from "@/components/shared/tools/query/keywords/steps/AskQuestion.vue";
import ChooseKeywords from "@/components/shared/tools/query/keywords/steps/ChooseKeywords.vue";
import AddKeywords from "@/components/shared/tools/query/keywords/steps/AddKeywords.vue";
import EvaluateKeywords from "@/components/shared/tools/query/keywords/steps/EvaluateSources.vue";
import GenerateReport from "@/components/shared/tools/query/keywords/steps/GenerateReport.vue";

export default {
	name: "QueryKeywords",

	components: {
		AskQuestion,
		ChooseKeywords,
		AddKeywords,
		EvaluateKeywords,
		GenerateReport,
	},

	computed: {
		...mapFields("query", {
			current_step: "keywords.current_step",
		}),
	},
};
</script>

<style></style>
