<template>
	<v-card flat class="wr_grey_1">
		<!-- Asked question -->
		<div>
			<div class="font-weight-bold subtitle-1">
				Keywords for "{{ step1_question }}"-
			</div>
		</div>

		<v-chip-group column class="mt-2">
			<v-chip
				v-for="keyword in keywords"
				:key="keyword.id"
				color="info"
				outlined
				close
				@click:close="removeKeyword(keyword.id)"
			>
				{{ keyword.name }}
			</v-chip>
			<AddKeywordDialog @on-keyword-create="onKeywordCreate"></AddKeywordDialog>
		</v-chip-group>

		<div align="center" class="mt-3">
			<AppButton
				label="app.submit"
				icon="send"
				@click="submit()"
				color="success"
			></AppButton>
		</div>

		<div v-if="step3_answer && step3_answer.result" class="mt-2 subtitle-1 ">
			<div class="font-weight-bold mt-5">
				Less relevant keywords-
			</div>
			<div class="mt-2">
				{{ step3_answer.result }}
			</div>
		</div>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryKeywordsSteps } from "@/utils/steps";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";
import AddKeywordDialog from "@/components/shared/tools/query/keywords/AddKeywordDialog.vue";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

export default {
	name: "QueryAddKeywords",

	mixins: [askQuestionMixin, toolsQueryMixin],

	components: {
		AddKeywordDialog,
	},

	computed: {
		...mapFields("query", {
			step1_question: "keywords.step1_question",
			step2_keywords: "keywords.step2_keywords",
			step3_keywords: "keywords.step3_keywords",
			step3_answer: "keywords.step3_answer",
			current_step: "keywords.current_step",
		}),

		keywords() {
			return this.step3_keywords;
		},
	},

	watch: {
		step3_keywords: {
			deep: true,
			handler() {
				this.step3_answer = null;
			},
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtk-move-to-evaluate-keywords", (payload) => {
				try {
					let new_keywords = this.step3_keywords.filter((item) =>
						item.hasOwnProperty("new"),
					);
					if (new_keywords.length < 5) {
						throw new Error("Please add 6 new keywords.", {
							cause: "werCustom",
						});
					}

					if (!this.step3_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}

					let non_relevant_keywords = this.step3_answer.result
						.replace(/(\r\n|\n|\r)/gm, "")
						.split(", ");
					if (this.step3_keywords.length - non_relevant_keywords.length < 5) {
						throw new Error("At least 5 keywords should be relevant.", {
							cause: "werCustom",
						});
					}

					this.current_step = queryKeywordsSteps[3];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async init() {
			if (!this.step3_keywords || !this.step3_keywords.length) {
				this.step3_keywords = this.step2_keywords
					.filter((item) => item.selected)
					.map((item) => {
						return { ...item, selected: false };
					});
			}
		},

		onKeywordCreate(payload) {
			this.step3_keywords.push({
				id: uid(),
				name: payload,
				selected: false,
				new: true,
			});
		},

		removeKeyword(keyword_id) {
			let result = this.step3_keywords.filter((item) => item.id !== keyword_id);
			this.step3_keywords = [...result];
		},

		async submit() {
			try {
				this.$loader.start();

				let selected_keywords = this.step3_keywords.map((item) => item.name);
				this.step3_answer = await this.prepareQuery(
					`In the list of following keywords, which keywords are less relevant to the topic "${
						this.step1_question
					}. Keywords- ${selected_keywords.join(",")}. Output -?"`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtk-move-to-evaluate-keywords");
	},
};
</script>

<style></style>
