<template>
	<v-card flat class="wr_grey_1">
		<div class="black--text pa-0">
			<!-- Asked question -->
			<div>
				<div class="font-weight-bold subtitle-1">
					Keywords for "{{ step1_question }}"-
				</div>
			</div>

			<!-- Generated keywords -->
			<v-chip-group column class="mt-2">
				<v-chip
					v-for="keyword in keywords"
					:key="keyword.id"
					color="info"
					:outlined="!keyword.selected"
					@click="markSelected(keyword.id)"
				>
					<v-icon v-if="keyword.selected" left
						>$vuetify.icons.values.check</v-icon
					>
					{{ keyword.name }}
				</v-chip>
			</v-chip-group>

			<div align="center" class="mt-2">
				<AppButton
					label="app.submit"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</div>

			<div v-if="step2_answer && step2_answer.result" class="mt-2 subtitle-1 ">
				<div class="font-weight-bold mt-5">
					Less relevant keywords-
				</div>
				<div class="mt-2">
					{{ step2_answer.result }}
				</div>
			</div>
		</div>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryKeywordsSteps } from "@/utils/steps";
import BackgroundInformation from "@/components/shared/tools/query/_common/BackgroundInformation.vue";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "QueryChooseKeywords",

	mixins: [askQuestionMixin, toolsQueryMixin],

	components: {
		BackgroundInformation,
	},

	computed: {
		...mapFields("query", {
			step1_question: "keywords.step1_question",
			step1_answer: "keywords.step1_answer",
			step2_keywords: "keywords.step2_keywords",
			step2_answer: "keywords.step2_answer",
			current_step: "keywords.current_step",
		}),

		keywords() {
			return this.step2_keywords;
		},
	},

	watch: {
		step1_question(newVal, oldVal) {
			this.step1_answer = this.step2_toc = null;
			this.attempts = [];
		},
	},

	mounted() {
		this.init();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtk-move-to-add-keywords", (payload) => {
				try {
					if (!this.step2_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}
					this.current_step = queryKeywordsSteps[2];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async init() {
			if (!this.step2_keywords) {
				this.step2_keywords = JSON.parse(this.step1_answer.result);
			}
		},

		async markSelected(keyword_id) {
			this.step2_keywords = this.step2_keywords.map((keyword) => {
				if (keyword.id == keyword_id) {
					return { ...keyword, selected: !keyword.selected };
				}
				return keyword;
			});
		},

		async submit() {
			try {
				this.$loader.start();

				let selected_keywords = this.step2_keywords
					.filter((item) => item.selected)
					.map((item) => item.name);

				this.step2_answer = await this.prepareQuery(
					`In the list of following keywords, which keywords are less relevant to the topic "${
						this.step1_question
					}. Keywords- ${selected_keywords.join(",")}. Output -?"`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtk-move-to-add-keywords");
	},
};
</script>

<style></style>
