<template>
	<div>
		<v-form ref="form">
			<v-row>
				<v-col>
					<v-text-field
						v-model="step1_question"
						:label="$t('app.topic_curious_about')"
						:rules="[rules.required]"
						outlined
					>
						<span slot="append">
							<SpeechToText
								:text="step1_question"
								@speechend="onSpeechEnd($event.text)"
							></SpeechToText>
						</span>
						<span slot="append" class="ms-3">
							<TextToSpeech :text="step1_question"></TextToSpeech>
						</span>
						<span slot="append" class="ms-3">
							<Translator :text="step1_question"></Translator>
						</span>
					</v-text-field>
				</v-col>
				<!-- <v-col sm="4">
					<BackgroundInformation></BackgroundInformation>
				</v-col> -->
				<v-col sm="2">
					<AppButton
						label="app.submit"
						:rounded="false"
						:block="true"
						:x_large="true"
						icon="send"
						@click="submit()"
						color="success"
					></AppButton>
				</v-col>
			</v-row>
		</v-form>

		<v-chip-group column>
			<v-chip
				v-for="keyword in keywords"
				:key="keyword.id"
				color="info"
				outlined
			>
				{{ keyword.name }}
			</v-chip>
		</v-chip-group>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryKeywordsSteps } from "@/utils/steps";
import BackgroundInformation from "@/components/shared/tools/query/_common/BackgroundInformation.vue";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "TOCAskQuestion",

	mixins: [askQuestionMixin, toolsQueryMixin],

	components: {
		BackgroundInformation,
	},

	computed: {
		...mapFields("query", {
			grade: "keywords.grade",
			current_step: "keywords.current_step",
			step1_question: "keywords.step1_question",
			step1_answer: "keywords.step1_answer",
			step2_keywords: "keywords.step2_keywords",
			step2_answer: "keywords.step2_answer",
			step3_keywords: "keywords.step3_keywords",
			step3_answer: "keywords.step3_answer",
			step4_keywords: "keywords.step4_keywords",
			step4_answer: "keywords.step4_answer",
			step4_selected_sources: "keywords.step4_selected_sources",
		}),

		keywords() {
			return (
				this.step1_answer &&
				this.step1_answer.result &&
				JSON.parse(this.step1_answer.result)
			);
		},
	},

	watch: {
		step1_question() {
			this.grade = this.step1_answer = this.step2_keywords = this.step2_answer = this.step3_keywords = this.step3_answer = this.step4_keywords = this.step4_answer = null;
			this.step4_selected_sources = [];
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qtk-move-to-choose-keywords", (payload) => {
				try {
					if (!this.step1_question || !this.step1_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}
					this.current_step = queryKeywordsSteps[1];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				this.$loader.start();

				if (!this.$refs.form.validate()) return;

				this.step1_answer = await this.prepareQuery(
					`Generate a JSON of 15 keywords related to "${this.step1_question} in a style of a researcher. The JSON should be in this format - [{"id": 1, "name": "keyword_name", "selected": false}]."`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onSpeechEnd(text) {
			try {
				// Save emitted value by microphone to answer
				this.step1_question = text;

				/**
				 * When click on mic icon, the focus will be detached from the input, so,
				 * after off the mic, enable the focus to input element again because
				 * answer will be saved only on blur event and to trigger blur event
				 * we need to focus first.
				 */
				this.$nextTick(() => {
					// There are two index in $el array, 0th is label and 1st is input.
					this.$refs.form.$el[1].focus();
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qtk-move-to-choose-keywords");
	},
};
</script>

<style></style>
