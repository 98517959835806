<template>
	<div>
		<v-form ref="form">
			<!-- Student name -->
			<v-text-field
				v-model="form.user_name"
				label="Name"
				outlined
				:rules="[rules.required]"
			></v-text-field>

			<!-- Teacher name -->
			<v-text-field
				v-model="form.teacher_name"
				:rules="[rules.required]"
				label="Teacher name"
				outlined
			></v-text-field>

			<!-- Student grade -->
			<!-- <v-text-field
				v-model="form.grade"
				readonly
				label="Grade"
				outlined
			></v-text-field> -->

			<!-- Student reflection -->
			<v-text-field
				v-model="form.reflection"
				:rules="[rules.required]"
				label="Reflection"
				outlined
			></v-text-field>
		</v-form>

		<ul>
			<li v-for="(source, index) in step4_selected_sources" :key="index">
				<b>{{ source.title }}</b
				><br />
				{{ source.description }}<br />
				<a :href="source.url" target="_blank" rel="noopener noreferrer">
					{{ source.url }}
				</a>
			</li>
		</ul>

		<v-btn depressed color="success" class="mt-10" @click="generateReport()">
			Download Report
		</v-btn>

		<!-- Report Preview -->
		<div>
			<div
				v-show="show"
				style="padding: 40px;width: 100%;overflow-wrap: break-word;"
				id="downloadable-container"
			>
				<v-row style="margin-top: 10px">
					<v-col><b>Student Name</b></v-col>
					<v-col>{{ form.user_name }}</v-col>
				</v-row>
				<v-row style="margin-top: 10px">
					<v-col><b>Teacher Name</b></v-col>
					<v-col>{{ form.teacher_name }}</v-col>
				</v-row>
				<!-- <v-row style="margin-top: 10px">
					<v-col><b>Grade</b></v-col>
					<v-col>{{ form.grade }}</v-col>
				</v-row> -->
				<v-row style="margin-top: 10px">
					<v-col><b>Reflection</b></v-col>
					<v-col>{{ form.reflection }}</v-col>
				</v-row>
				<v-row style="margin-top: 10px">
					<v-col>
						<ul>
							<li
								v-for="(source, index) in step4_selected_sources"
								:key="index"
							>
								<b>{{ source.title }}</b
								><br />
								{{ source.description }}<br />
								<a :href="source.url" target="_blank" rel="noopener noreferrer">
									{{ source.url }}
								</a>
							</li>
						</ul>
					</v-col>
				</v-row>
				<div style="margin-top: 30px">Sincerely,</div>
				<div style="margin-top: 10px">{{ form.user_name }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import rules from "@/utils/validation";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
	name: "QueryKeywordsGenerateReport",

	data() {
		return {
			rules,
			show: false,
			form: {
				user_name: null,
				teacher_name: null,
				grade: this.$store.state.query.toc.grade,
				reflection: null,
			},
		};
	},

	computed: {
		...mapState({
			step4_selected_sources: (state) =>
				state.query.keywords.step4_selected_sources,
		}),
	},

	methods: {
		generateReport() {
			try {
				if (!this.$refs.form.validate()) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}

				this.show = true;

				window.html2canvas = html2canvas;

				var pdf = new jsPDF({
					unit: "px",
					format: "a4",
					putOnlyUsedFonts: true,
					orientation: "portrait",
					hotfixes: ["px_scaling"],
				});
				const elem = document.getElementById("downloadable-container");
				pdf.html(elem, {
					callback: (pdf) => {
						pdf.save("test.pdf");
						this.show = false;
					},
					autoPaging: true,
					html2canvas: {
						useCORS: true,
						width: elem.scrollWidth,
						height: elem.scrollHeight,
					},
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},
};
</script>
