<template>
	<KeywordsQuery></KeywordsQuery>
</template>

<script>
import KeywordsQuery from "@/components/shared/tools/query/keywords/Index.vue";

export default {
	name: "KeywordsQueryPage",

	components: {
		KeywordsQuery,
	},
};
</script>

<style></style>
